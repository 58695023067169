.bannerBorder {
  background: linear-gradient(to right, #203662 50%, #c50f3c 50%) bottom;
  background-repeat: no-repeat;
  background-size: 100% 3px;
  padding-bottom: 3px;
}
.bannerImage {
  background-image: url("../images/unifiedDashboardBanner.png");
  background-repeat: no-repeat;
  background-position: top center;
  height: calc(100vh - 349px);
  background-size: cover;
}
.welcomeTitle {
  h1 {
    font-size: 24px;
    font-weight: 600;
    span {
      display: block;
      font-size: 14px;
      font-weight: normal;
      color: #54565b;
    }
  }
}
.form-floating > .form-select {
  background-image: url("../images/dropdownIcon.svg");
  border: 1px solid #ced4da;
  border-radius: 0;
  padding-top: 17px !important;
  min-height: calc(2.5rem + 2px) !important;
  height: calc(2.5rem + 2px) !important;
  font-size: 12px !important;
  color: #231f20 !important;
}
.form-select:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}
.form-floating > label {
  padding-top: 5px !important;
  padding-left: 7px !important;
  font-size: 10px !important;
  color: #231f20;
}
.form-floating > .form-select label {
  font-size: 10px !important;
  color: #231f20;
  padding: 0 !important;
  margin: 0 0 !important;
  top: 12px !important;
  left: 6px;
  // opacity: 0.65;
  // transform: scale(0.85) translateY(-1.1rem) translateX(0.15rem);
}

.nextApplicationsRow {
  background-color: #203662;
  // padding-top: 31px;
  // padding-bottom: 31px;
  img {
    max-height: 200px;
  }
  span {
    display: block;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.48px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    display: none;
  }
}
.iconBox {
  // padding-left: 30px;
  // padding-right: 30px;
  cursor: pointer;
}
.damsSelectWidth {
  width: 180px;
}
.select {
  border: solid 1px #d4d4d4;
}
.userName {
  font-size: 12px;
  color: #fff;
  background-color: #203662;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: 600;
  margin-left: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.damsList {
  :first-child {
    color: #54565b;
    font-size: 10px;
  }
  span {
    display: block;
    font-size: 12px;
    text-align: left;
    color: #1e1e1e;
    font-weight: 600;
  }
}
.dropdown-menu {
  border-radius: 12px !important;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16) !important;
  border: none !important;
}
.dropdown-item {
  font-size: 12px;
  color: #222222;
}
.dropdown-item:active {
  color: #222222;
  text-decoration: none;
  background-color: #f0f0f0;
}
.pt15 {
  padding-top: 15px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.logOutButton .dropdown-menu {
  min-width: 184px !important;
  max-width: 184px !important;
}
.ptpb15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
