.logoutCard {
  .logoutCardName {
    text-align: center;
    font-weight: 700;
    color: #000;
    margin-top: 6px;
  }
  .logoutCardEmail {
    text-align: center;
    font-size: 10px;
    padding-left: 12px;
    padding-right: 12px;
    color: #000;
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .userName {
    font-size: 30px;
    color: #fff;
    background-color: #203662;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .userNameOuterLine {
    border: solid 2px #dae5f2;
    border-radius: 50%;
    padding: 3px;
  }
  .logoutBtn {
    padding: 8px 0 !important;
    border-radius: 16px;
    background-color: #f3f6fa;
    min-width: 130px;
    font-size: 12px;
    color: #203662;
    text-decoration: none;
    margin-top: 16px;
    font-weight: 600;
    &:hover {
      color: #203662;
      background-color: #f3f6fa;
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
      box-shadow: none;
    }
  }
  ul.appDropdown :last-child {
    margin-bottom: 20px !important;
  }
}
